import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../website-assets/images/logo.svg";
import favicon from "../../website-assets/images/favicon.png";
import { ToastContainer, toast } from "react-toastify";


import { userActivity } from "../services/api";

const Head = (props) => {
  const [isauth, setIsAuth] = useState(false);
  const [profileImage, setProfileImage] = useState();
  const [activity, setActivity] = useState("");
  const notify = () =>
    toast("Your Order Completed!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  const navigate = useNavigate();
  useEffect(() => {
    const authentication = sessionStorage.getItem("token");
   
    if (authentication) {
      setIsAuth(true);
      setProfileImage(sessionStorage.getItem('profile_image'));
      getActivity();
    }

  }, []);

  const getActivity=async()=>{
    const token = sessionStorage.getItem('token');
    const userId = sessionStorage.getItem("user_id");
      const response = await userActivity(token,userId);
      if(response.data > 0){
        setActivity(`You have ${response.data} pending orders`)
      }
     
  }
  
  const handleLogout = (e) => {
    e.preventDefault();
    sessionStorage.clear();
    navigate("/login");
  };
  return (
    <>
      <nav class="navbar navbar-expand-lg bg-dark-ben fixed-top z-4 main-menu py-lg-4">
        <div class="container">
          <Link class="navbar-brand" to="/">
            <img src={logo} alt="Ben GP Logo" class="img-fluid" />
          </Link>
          <button
            class="navbar-toggler btn btn-primary-ben py-2 px-3 rounded-0"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <i class="fa fa-bars"></i>
          </button>
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav me-auto mb-2 mb-lg-0 ms-lg-5">
              <li class="nav-item">
                <Link
                  to="/"
                  class={`transition nav-link ${
                    props.component == "osrsGold" ? "active" : ""
                  }`}
                  aria-current="page"
                >
                  OSRS Gold
                </Link>
              </li>
              <li class="nav-item">
                <Link
                  class={`transition nav-link ${
                    props.component == "rs3Gold" ? "active" : ""
                  }`}
                  to="/rs3-gold"
                >
                  RS3 Gold
                </Link>
              </li>
              <li class="nav-item">
                <Link
                  class={`transition nav-link ${
                    props.component == "osrsAccounts" ? "active" : ""
                  }`}
                  to="/all-osrs-accounts"
                >
                  OSRS Accounts
                </Link>
              </li>
              <li class="nav-item">
                <Link
                  class={`transition nav-link ${
                    props.component == "rs3Accounts" ? "active" : ""
                  }`}
                  to="/all-rs3-accounts"
                >
                  RS3 Accounts
                </Link>
              </li>
              <li class="nav-item">
                <Link
                  class={`transition nav-link ${
                    props.component == "osrsItems" ? "active" : ""
                  }`}
                  to="/all-osrs-items"
                >
                  OSRS Items
                </Link>
              </li>
              <li class="nav-item">
                <Link
                  class={`transition nav-link ${
                    props.component == "rs3Items" ? "active" : ""
                  }`}
                  to="/all-rs3-items"
                >
                  RS3 Items
                </Link>
              </li>
            </ul>
            <div class="d-flex gap-4 align-items-center">
              {isauth ? (
                <>
                  <div class="dropdown notif">
                    <a
                      href=""
                      class="notification-bell fs-5 transition position-relative"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      {activity?(<i class="fa fa-circle fs-8 text-primary-ben position-absolute top-0 start-100 translate-middle"></i>):(<></>)}
                      <i class="fa fa-bell"></i>
                    </a>
                    <ul class="dropdown-menu p-0 pb-3 end-0 start-auto rounded-0 bg-dark mt-3">
                      <li class="p-4 text-white border-bottom">
                        <strong class="fs-16 fw-600 text-uppercase">
                          Activity
                        </strong>
                      </li>
                      {activity?(  <li class="d-block mt-2">
                        <a
                          class="dropdown-item px-4 py-3 fs-13 d-flex gap-2 align-items-center transition"
                          href=""
                        >
                          <i class="fa fa-circle fs-8 text-primary-ben"></i>{" "}
                          <span>
                              {activity}
                          </span>
                        </a>
                      </li>):(<></>)}
                    
                      {/* <li>
                        <a
                          class="dropdown-item px-4 py-3 fs-13 d-flex gap-2 align-items-center transition"
                          href=""
                        >
                          <i class="fa fa-circle fs-8 text-primary-ben"></i>{" "}
                          <span>
                            Your <strong>ORDER</strong> has been canceled.
                          </span>
                        </a>
                      </li> */}
                    </ul>
                  </div>
                  <div class="dropdown notif ms-2">
                    <a
                      href=""
                      class="profile-pic"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img
                        src={profileImage ? profileImage : favicon}
                        alt="Profile Pic"
                        width="40"
                        height="40"
                        class="img-fluid border-primary-ben"
                      />
                    </a>
                    <ul class="dropdown-menu bg-dark end-0 start-auto rounded-0">
                      {/* <li><a class="dropdown-item p-3 fs-13 transition" href=""><i class="me-2 fa fa-user text-primary-ben"></i> Profile</a></li>
							        <li><a class="dropdown-item p-3 fs-13 transition" href=""><i class="me-2 fa fa-key text-primary-ben"></i> Change Password</a></li> */}
                      <li>
                        <Link
                          class="dropdown-item p-3 fs-13 transition"
                          to="/dashboard"
                        >
                          <i class="me-2 fa fa-user text-primary-ben"></i>{" "}
                          Dashboard
                        </Link>
                      </li>
                      <li>
                        <Link
                          class="dropdown-item p-3 fs-13 transition"
                          to="/profile"
                        >
                          <i class="me-2 fa fa-user text-primary-ben"></i>{" "}
                          Profile
                        </Link>
                      </li>
                      <li>
                        <a
                          class="dropdown-item p-3 fs-13 transition"
                          href=""
                          onClick={handleLogout}
                        >
                          <i class="me-2 fa fa-sign-out text-primary-ben"></i>{" "}
                          Logout
                        </a>
                      </li>
                    </ul>
                  </div>
                </>
              ) : (
                <>
                  <Link
                    to="/login"
                    name="btn"
                    class="btn btn-primary-ben  rounded-4 fw-bold p-3 text-uppercase"
                  >
                    Login
                  </Link>
                </>
              )}
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};
export default Head;
