import React from "react";
import axios from "axios";
import {useState, useEffect} from "react";
import {useNavigate} from "react-router-dom";
import { Link } from "react-router-dom";
import Logo from '../../website-assets/images/logo.svg'
import visa from "../../website-assets/images/visa.svg";
import bitcoin from "../../website-assets/images/bitcoin.png";
import { getSupportedcoins } from "../services/api";
import { coinTransaction } from "../services/api";
import { getCheckoutUrl } from "../services/api";
import logo from "../../website-assets/images/logo-footer.png";
import { Helmet } from 'react-helmet-async';



const Checkout=()=>{
	const [element,setElement] = useState({});
	const [price, setPrice] = useState(0);
	const [accountcheck, setAccountCheck]= useState(false);
	const [account, setAccount] = useState("");
	const [product ,setProduct] = useState("");
	const [gold , setGold] = useState("");
	const [supportedcoins, setSupportedcoins]=useState({});
	const [selectedcoin, setSelectedCoin]=useState('USD');
	const [from_currency,setFormCurrency]= useState('USD');
	const [accountemail, setAccountEmail] = useState("");
	const [category, setCategory] = useState(0);
	const [runescapename, setRunescapename] = useState();
	const [runescapeemail, setRunescapeemail] = useState();
	const [selectedPayment, setSelectedPayment] = useState('');
	const [quantity, setQuantity] = useState(0);
	const [type, setType] =  useState();
	const [isloading, setIsLoading] = useState(false);
	const canonicalUrl = `${window.location.href}`;
	const navigate = useNavigate();
	useEffect(() => {
		// getCoins();
		setType(sessionStorage.getItem('type'));
		setSelectedPayment(sessionStorage.getItem('paymentMethod'));
		const sessiontype =sessionStorage.getItem('type')

			if(!sessionStorage.getItem("selectedAccount")){
			navigate("/");
			}
		else{
			setElement(JSON.parse(sessionStorage.getItem('selectedAccount')));
			if(sessiontype === "1"){
				setPrice(JSON.parse(sessionStorage.getItem('selectedAccount')).cost);
				setAccountCheck(true);
				setAccount(JSON.parse(sessionStorage.getItem('selectedAccount')));
	
			}else if (sessiontype === '0'){
				setProduct(JSON.parse(sessionStorage.getItem('selectedAccount')));
				 setPrice(JSON.parse(sessionStorage.getItem('selectedAccount')).minimum_buy_limit * JSON.parse(sessionStorage.getItem('selectedAccount')).price);
				 setQuantity(JSON.parse(sessionStorage.getItem('selectedAccount')).minimum_buy_limit);
			}else {
				setGold(JSON.parse(sessionStorage.getItem('selectedAccount')));
				setCategory(JSON.parse(sessionStorage.getItem('selectedAccount')).category);
				setPrice(JSON.parse(sessionStorage.getItem('selectedAccount')).usdbuyValue);
				setQuantity(JSON.parse(sessionStorage.getItem('selectedAccount')).mbuyValue);
				setRunescapename(JSON.parse(sessionStorage.getItem("selectedAccount")).runescapename);
				setRunescapeemail(sessionStorage.getItem("user_email"));
			}
		}
	},[]);

	// const getCoins=async()=>{
	// 	const token = sessionStorage.getItem('token');
	// 	const response =await getSupportedcoins(token);
	// 	setSupportedcoins(response.data);
	// }

	const handleCoin=(e)=>{
		setSelectedCoin(e.target.value);
	}


	const handlePaymentChange = (event) => {
		setSelectedPayment(event.target.value);
		sessionStorage.setItem("paymentMethod",event.target.value);
	};

	  const handleChangerunescapename=(e)=>{
			setRunescapename(e.target.value);
	  }
	  const handleChangeEmail=(e)=>{
		setAccountEmail(e.target.value)
	  }
	  
	//   const [formData, setFormData] = useState({
	// 	name: '',
	// 	email: '',
	//   });
		const [errors, setErrors] = useState({});
		// const handleChange = (e) => {
		// 	const { name, value, type, checked } = e.target;
		// 	setFormData({
		// 	  ...formData,
		// 	  [name]: type === 'checkbox' ? checked : value
		// 	});
		//   };
		  const validate = () => {
			let errors = {};
			if(type == '2')
			{
				return true;
			}
			// if (!runescapename) {
			//   errors.runescapename = 'Runescapename is required';
			// } 
			if(sessionStorage.getItem('type') === '1'  && !accountemail){
				errors.runescapeemail= 'Email is required';
			}
			
			setErrors(errors);
		
			return Object.keys(errors).length === 0;
		  };

		  const handleSubmit = async (e) => {
			sessionStorage.setItem("orderMessageSent", "true");
			e.preventDefault();
			if (validate()) {
				const token = sessionStorage.getItem('token');
				const id = element.id;
				const userid = sessionStorage.getItem("user_id");
				const item_type = sessionStorage.getItem("item_type");
				const updatedFormData = {
					runescapeemail,
					runescapename,
					price,
					id,
					quantity,
					type,
					userid,
					category,
					selectedcoin,
					item_type,
					accountemail
				  };
				setIsLoading(true);
				if(selectedPayment === "bitcoin")
				{
					const response =await coinTransaction(token,updatedFormData);
					sessionStorage.removeItem("selectedAccount");
					sessionStorage.removeItem("type");
					if(response){
						sessionStorage.setItem("orderGenerated", JSON.stringify(response.data.order));
						window.location.href = response.data.url;	
					}			
				}else if(selectedPayment === "visa")
				{
					const response = await getCheckoutUrl(token, updatedFormData);
					sessionStorage.removeItem("selectedAccount");
					sessionStorage.removeItem("type");
					if(response){
						console.log(response);
						sessionStorage.setItem("orderGenerated",  JSON.stringify(response.data.order));
						setIsLoading(false);
						window.location.href = response.data.checkout.redirect;	
					}
				}

				
			}
		  };

		  const incrementQuantity =(e)=>{
			e.preventDefault();
				setQuantity(parseInt(quantity)+1);
				const selectedAccountPrice = parseFloat(JSON.parse(sessionStorage.getItem('selectedAccount')).price);
				const updatedPrice = (parseFloat(price) + selectedAccountPrice).toFixed(2);
				setPrice(parseFloat(updatedPrice));		  
			}
		  const decrementQuantity=(e)=>{
			e.preventDefault();
			const buylimit = JSON.parse(sessionStorage.getItem('selectedAccount')).minimum_buy_limit;
			if(quantity > buylimit){
				setQuantity(quantity-1);
				const selectedAccountPrice = JSON.parse(sessionStorage.getItem('selectedAccount')).price;
				const updatedPrice = (price - selectedAccountPrice).toFixed(2);
				setPrice(updatedPrice);
			}
		  }

	return (
		<>
		<Helmet>
			<title>Checkout</title>
			<link rel="canonical" href={`${canonicalUrl}`} />
      	</Helmet>
		<div className="login-register">
						<em class="over position-absolute bg-black opacity-75 bottom-0 top-0 end-0 start-0"></em>
						<div class="container vh-100 position-relative z-1">
						<div class="row align-items-center h-100">
							<div class="col-lg-2 col-md-1"></div>
							<div class="col-lg-8 col-md-10">
							<section class="bg-dark-ben rounded-3 overflow-hidden shadow p-4 my-4">
								<div class="row">
								<div class={account || product ?'col-md-6':'col-md-12'}>
									{isloading?(<>								
									<div className="text-center order-loading-div ">
										<div className="spinner-border text-warning" role="status"></div>
										<br></br>
										<div className="loader-text mt-4">
										<span className="text-warning mt-4">Processing Order</span>
										</div>
									</div>
									</>):(<>
										<div class="text-center my-4"><Link to="/"><img src={Logo} alt="Logo" class="img-fluid" width="130" /></Link><br /><h1 class="h3 fw-bold text-white py-4">Finalize Your Order</h1></div>
										<form onSubmit={handleSubmit}>
										{errors.apiError && <span className="text-danger">{errors.apiError}</span>}
										{errors.runescapename && <span className="text-danger">{errors.runescapename}</span>}
													{!accountcheck?
														<div class="input-group mb-4 mt-2 bg-light-ben">
																<input type="text" class="shadow-none form-control rounded-0 bg-light-ben border-light-ben text-light-ben" name="runescapename" placeholder="Enter Your Runescape Name*" value={runescapename} onChange={handleChangerunescapename} />
															</div>:<></>
													}
													{accountcheck? <><span className="text-warning">Account login details will be send to this email</span><br></br><br></br>
														{errors.runescapeemail && <span className="text-danger mt-3">{errors.runescapeemail}</span>}
													<div class="input-group mb-4 mt-2 bg-light-ben">
														<input type="text" class="shadow-none form-control rounded-0 bg-light-ben border-light-ben text-light-ben" name="runescapename" placeholder="Enter Your Email Address*" value={runescapeemail} onChange={handleChangeEmail} />
													</div></>:<></>}
										{element && type == '0' && price && quantity?(
											<div className="invoice-products">
											<span class="heading">Order Summary</span>
											<table className="mt-2">
												<tr>
													<td>Price:</td>
													<td>${price?JSON.parse(sessionStorage.getItem('selectedAccount')).price:<>0</>}</td>
												</tr>
												<tr>
													<td>Quantity:</td>
													<td><div class="input-group mb-4 mt-2 bg-light-ben">
													<button class="btn btn-primary-ben rounded-0 fw-bold p-3 text-uppercase" onClick={incrementQuantity}><i class="fa fa-plus"></i></button><input type="text" class="ml-2 w-40 shadow-none form-control rounded-0 bg-light-ben border-light-ben text-light-ben" value={quantity}	 placeholder="Quantity"  readOnly/><button class="btn btn-primary-ben rounded-0 fw-bold p-3 text-uppercase" onClick={decrementQuantity}><i class="fa fa-minus"></i></button>		
												</div></td>
												</tr>
												<tr>
													<td>Sub Total:</td>
													<td>$ {price?JSON.parse(sessionStorage.getItem('selectedAccount')).price:<>0</>} x {quantity}</td>
												</tr>
												<tr>
													<td>Total:</td>
													<td>${price}</td>
												</tr>
												</table>
											</div>):element && type == '1'?(
												<>
													<div className="invoice-products">
													<span class="heading">Order Summary</span>
													<table className="mt-2">
														<tr>
															<td>Total:</td>
															<td>${price}</td>
														</tr>
													</table>
													</div>
												</>):element && type == '2' && price && quantity?(<><div className="invoice-products">
													<span class="heading">Order Summary</span>
													<table className="mt-2">
														<tr>
															<td>Total:</td>
															<td>${price}</td>
														</tr>
													</table>
													</div>	</>):(<></>)
												}
												{/* {selectedPayment == 'bitcoin' && supportedcoins?(<><div class="row">
													<div className="col d-flex">
													<label className="p-4 coin-label">Select Coin</label>
													<select class="input-group-text mt-1 rounded-0 bg-dark-ben border-0 text-light-ben px-2" onChange={handleCoin}>
													{Object.keys(supportedcoins).map((coinKey) => (
														<option key={coinKey} value={coinKey}>
														{coinKey}
														</option>
													))}
													</select>
													</div>
												</div></>):(<></>)} */}
												<h3 class="text-light-ben py-4">
												<strong class="fw-bold">Payment Method:</strong>
												</h3>
												<div class="row">
													<div class="col">
														<label class="payments transition d-block text-center">
															<input
																type="radio"
																name="payment"
																value="visa"
																class="d-none"
																checked={selectedPayment === 'visa'}
																onChange={handlePaymentChange}
															/>
															<img
																src={visa}
																alt="Visa Master Card"
																class="img-fluid"
															/>
														</label>
													</div>
													<div class="col">
														<label class="payments transition d-block text-center">
															<input
																type="radio"
																name="payment"
																value="bitcoin"
																class="d-none"
																checked={selectedPayment === 'bitcoin'}
																onChange={handlePaymentChange}
															/>
															<img
																src={bitcoin}
																alt="Bitcoin"
																class="img-fluid bitcoin"
																width="100"
															/>
														</label>
													</div>
													{/* <div class="col">
														<label class="payments transition d-block text-center">
															<input
																type="radio"
																name="payment"
																value="binance"
																class="d-none"
																checked={selectedPayment === 'binance'}
																onChange={handlePaymentChange}
															/>
															<img
																src={binance}
																alt="Binance"
																class="img-fluid"
															/>
														</label>
													</div> */}
												</div>
												
												
										
										<div class="mb-4 mt-4">
										<button name="btn" class="btn btn-primary-ben w-100 rounded-0 fw-bold p-3 text-uppercase"  disabled={!selectedPayment}>Proceed Checkout</button>
										</div>
										</form>
									</>)}
									
								</div>
								{account?<div class="col-md-6 ps-md-6  pt-4">
									<strong class="d-block text-light-ben pt-5 lh-150">SELECTED ACCOUNT</strong>
									<div class="items-box p-3 position-relative mt-4  mb-4">
									<div class="d-flex gap-3 ">
										<div class="img-holder position-relative overflow-hidden  rounded-1">
										<span class="bg-danger fs-12 py-1 px-2 d-inline-block position-absolute start-0 top-0 mt-1 ms-1 text-white">
											NEW
										</span>
										<img
											src={account.images && account.images.length > 0 ?`${process.env.REACT_APP_API_BASE_URL}${account.images[0].account_image}` : logo}
											alt="Logo"
											class="transition account-image rounded-1"
											width="100"
											height="100"
										/>
										</div>
										<div class="details-account ">
										<strong class="d-block text-primary-ben mt-1">
											{account.title}
										</strong>
										<div>
											<div class="d-flex gap-2">
											<div class="d-flex gap-1 my-3 fs-12">
												<i
												class="fa fa-sign-in text-primary-ben fs-11"
												title="Account Level"
												></i>{" "}
												<span class="text-white">
												{account.level}
												</span>
											</div>
											<div class="d-flex gap-1 my-3 fs-12">
												<i
												class="fa-solid fa-list text-primary-ben fs-11"
												title="Price"
												></i>{" "}
												<span class="text-white">{(account.offense == '1')?'Offense':'No offence'}</span>
											</div>
											<div class="d-flex gap-1 my-3 fs-12">
												<i
												class="fa fa-clock text-primary-ben fs-11"
												title="Sold"
												></i>{" "}
												<span class="text-white">Delivery: Instant</span>
											</div>
											</div>
											
										
										</div>
										<span className="price-account">
												<i
													class="fa fa-dollar text-primary-ben fs-14 dollar-icon "
													title="Price"
												></i>{" "}
												<span class=" pricing-account">{account.cost}</span>
										</span>
										<div className="mt-3">
											<Link
												to={`/detail/${account.id}`}
												class=" rounded-0 px-3 my-1 fs-12 see-detail "
											>
												SEE DETAIL
											</Link>
											</div>
										</div>
										
									</div>
									
									</div>
											</div>:<></>}
								{product?<div class="col-md-6 ps-md-6  pt-4">
												<strong class="d-block text-light-ben pt-5 lh-150">SELECTED PRODUCT</strong>
												<div class="items-box mt-3 p-3 position-relative transition">
													{console.log(product)}
												<a
													href="javascript:;"
													class="img-holder text-center position-relative d-block text-white"
												>
													<span class="bg-success fs-12 py-1 px-2 d-inline-block position-absolute start-0 top-0">
													FEATURED
													</span>
													<img
													src={`${process.env.REACT_APP_API_BASE_URL}/uploads/${product.image}`}
													alt="Gloves"
													class="img-fluid transition mb-3"
													style={{ maxHeight: "200px" }}
													/>
												</a>
												<a
													href="javascript:;"
													class="text-primary-ben fw-bold fs-5"
												>
													{product.name}
												</a>
												<span class="d-block py-3 text-white">
													$ {product.price} / unit
												</span>
												<div class="d-flex justify-content-between align-items-center">
													{/* <Link
														to={`/detail/item/${product.id}`}
														class="btn btn-primary-ben rounded-0 px-3 my-1 fs-12 see-detail "
														>
														SEE DETAIL
														</Link> */}
													
													<span class="  fs-12 my-2 text-light">
													<i class="fa fa-clock me-1"></i> 20 mins
													</span>
												</div>
												</div>
											</div>:<></>}
								
											
											</div>
										</section>
										</div>
										<div class="col-lg-2 col-md-1"></div>
									</div>
									</div>
					</div>
		</>
		
	
	);

}
export default Checkout;