import React, { useEffect, useState } from "react";
import { osrsAccounts, rs3Accounts } from "../services/api";
import { Link } from "react-router-dom";
import logo from "../../website-assets/images/logo-footer.png";
import { getlevelAccounts } from "../services/api";
import { getSearhedAccounts } from "../services/api";
import { useNavigate } from "react-router-dom";
import { Helmet } from 'react-helmet-async';

const Accounts = (props) => {
  const navigate = useNavigate();
  const [accounts, setAccounts] = useState([]);
  const [loader, setLoader] = useState(false);
  const [selectedlevel, setSelectedLevel] = useState();
  const canonicalUrl = `${window.location.href}`;

  const handleBuyNow = (account) => {
    sessionStorage.setItem("selectedAccount", JSON.stringify(account));
    sessionStorage.setItem("type", "1");
    const isAuthenticated = sessionStorage.getItem("token");
    if (!isAuthenticated) {
      sessionStorage.setItem("redirectPath", "/checkout");
      navigate("/login");
      return;
    }else{
      navigate("/checkout")
    }
  };
  useEffect(() => {
    const navbarToggler = document.querySelector('.navbar-toggler');
    const navbarCollapse = document.querySelector('#navbarSupportedContent');

    if (navbarToggler && navbarCollapse.classList.contains('show')) {
      navbarToggler.click(); 
    }
    fetchdata();
  }, [props]);
 
  const fetchdata = async () => {
    setLoader(true);
    if (props.component === "OSRS") {
     
      const response = await osrsAccounts();
      setAccounts(response.data);
    } else {
      
      const response = await rs3Accounts();
      setAccounts(response.data);
    }
    setLoader(false);
  };
  const levelFilter=async(e)=>{
      const level = e.target.value;
      const response = await getlevelAccounts(level);
      setAccounts(response.data);
  }
   const handleSearchChange=async(e)=>{
      let key;
      if(e.target.value){
        key = e.target.value;
      }
      else{
        key = '0';
      }
      if(key){
        const response = await getSearhedAccounts(key);
        setAccounts(response.data);
      }
     
   }



  return (
    <div className="login-register mt-5">
       <Helmet>
        <title>{props.component}</title>
        <link rel="canonical" href={`${canonicalUrl}`} />
      </Helmet>
      <em class="over position-fixed bg-black opacity-85 bottom-0 top-0 end-0 start-0"></em>
      <div class="container position-relative z-1 mt-4">
        <div class="row mt-4">
          <div class="col-12 mt-4">
            <div class="row mt-4">
              <div class="col-sm-12 my-4">
                <div class="input-group mb-4 mt-2 bg-light-ben position-relative w-280">
                  <input
                    type="text"
                    class="shadow-none form-control rounded-0 bg-light-ben border-light-ben text-light-ben"
                    placeholder="Search..."
                    onChange={handleSearchChange}
                  />
                  <a href="javascript:;" class="btn pt-1 me-1 border-0">
                    <i class="fa fa-search text-light-ben mt-3"></i>
                  </a>
                </div>
              </div>
              {/* <div class="col-sm-6 my-4">
                <div class="input-group my-2 bg-light-ben w-280 float-end">
                  <select
                    class="form-select shadow-none rounded-0 bg-light-ben border-light-ben text-light-ben"
                    aria-label="Recommended"
                    onChange={levelFilter}
                  >
                    <option selected>Recommended</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </select>
                </div>
              </div> */}
              <div class="col-6">
                <h4 class="h5 fw-bold mb-0 pb-4 text-white">{props.component} Accounts</h4>
              </div>
              <div class="col-6">
                <p class="text-light-ben float-end fs-12">{accounts.length} Accounts</p>
              </div>
              {loader ? (
                <>
                  <div className="loader-card text-center my-4 h-20">
                    <div
                      className="spinner-border text-warning"
                      role="status"
                    ></div>
                  </div>
                </>
              ) : (
                accounts.map((account) => (
                  <div class="col-md-6">
                    <div class="items-box p-3 position-relative  mb-4">
                      <div class="d-flex gap-3 ">
                        <div class="img-holder position-relative overflow-hidden  rounded-1">
                          {/* <span class="bg-danger fs-12 py-1 px-2 d-inline-block position-absolute start-0 top-0 mt-1 ms-1 text-white">
                            NEW
                          </span> */}
                          <img
                            src={account.images && account.images.length > 0 ?`${process.env.REACT_APP_API_BASE_URL}${account.images[0].account_image}` : logo}
                            alt="Logo"
                            class="transition account-image rounded-1"
                            width="100"
                            height="100"
                          />
                        </div>
                        <div class="details-account ">
                          <strong class="d-block text-primary-ben mt-1">
                            {account.title}
                          </strong>
                          <div>
                            <div class="d-flex gap-2">
                              <div class="d-flex gap-1 my-3 fs-12">
                                <i
                                  class="fa fa-sign-in text-primary-ben fs-11"
                                  title="Account Level"
                                ></i>{" "}
                                <span class="text-white">
                                   {account.level}
                                </span>
                              </div>
                              <div class="d-flex gap-1 my-3 fs-12">
                                <i
                                  class="fa-solid fa-list text-primary-ben fs-11"
                                  title="Price"
                                ></i>{" "}
                                <span class="text-white">{(account.offense == '1')?'Offense':'No offence'}</span>
                              </div>
                              <div class="d-flex gap-1 my-3 fs-12">
                                <i
                                  class="fa fa-clock text-primary-ben fs-11"
                                  title="Sold"
                                ></i>{" "}
                                <span class="text-white">Instant</span>
                              </div>
                            </div>
                            
                           
                          </div>
                          <span className="price-account">
                                  <i
                                    class="fa fa-dollar text-primary-ben fs-14 dollar-icon "
                                    title="Price"
                                  ></i>{" "}
                                  <span class=" pricing-account">{account.cost}</span>
                          </span>
                          <div className="mt-3">
                              <Link
                                to={`/detail/${account.id}`}
                                class=" rounded-0 px-3 my-1 fs-12 see-detail "
                              >
                                SEE DETAIL
                              </Link>
                              <a
                              onClick={()=>handleBuyNow(account)}
                                class="btn btn-primary-ben rounded-0 px-3 my-1 fs-12 "
                              >
                                BUY NOW
                              </a>
                            </div>
                        </div>
                        
                      </div>
                      
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Accounts;
